import React from 'react'
import SearchArtworks from 'apps/private/search/algolia/components/SearchArtworks'
import { addStoreLocaleByCookie } from 'lib/storeLocale/client'
import styles from './findArtworks.module.scss'
import SALink from 'components/SALink'

const FindArtworks = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const navigateToSearch = (value): void => {
    const location = addStoreLocaleByCookie(`/search?query=${value}`)
    window.location.href = location
  }

  return (
    <div className={styles.find}>
      <div className={styles.column}>
        <h3 className='defaultTypographyH3'>Find Your Perfect Artwork</h3>
        <SearchArtworks
          placeHolder='Describe your perfect artwork...'
          updateSearch={navigateToSearch}
          showClearButton
        />
      </div>
      <div className={styles.column}>
        <h5 className='defaultTypographyH5'>Explore Popular Searches</h5>

        <div className={styles.row}>
          <SALink
            className='defaultTypographySmall'
            href='/search?query=Abstract%20Painting'
            rel='nofollow'
          >
            Abstract Painting
          </SALink>
          <SALink className='defaultTypographySmall' href='/search?query=Landscape' rel='nofollow'>
            Landscape
          </SALink>
          <SALink
            className='defaultTypographySmall'
            href='/search?query=Figurative%20Art'
            rel='nofollow'
          >
            Figurative Art
          </SALink>
          <SALink
            className='defaultTypographySmall'
            href='/search?query=Abstract%20Expressionism'
            rel='nofollow'
          >
            Abstract Expressionism
          </SALink>
        </div>
      </div>
    </div>
  )
}

export default FindArtworks
