import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getPath } from 'selectors/shared/location'

const mapStateToProps = createStructuredSelector({
  path: getPath,
})
export type ConnectorProps = {
  path: string
}
export default connect(mapStateToProps) // Component Calls Connect
