import React, { FC } from 'react'
import styled from 'styled-components'
import TrustPilotWidgetConnector, {
  ConnectorProps as TrustPilotWidgetConnectorProps,
} from 'containers/TrustPilotWidget'

type TrustPilotWidgetProps = TrustPilotWidgetConnectorProps & {
  path: string
  styles?: string | null | undefined
}

const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
  margin: 64px 0;
  ${(props) => props.styles};
  iframe {
    position: 'relative';
    height: 130px;
    width: '100%';
    border: 'none';
    display: 'block';
    overflow: 'hidden';
  }
`

const TrustPilotWidget: FC<TrustPilotWidgetProps> = (props) => {
  // Don't render if browse url path does not match the following...
  const isDesiredPath =
    props.path.search('artdetail') ||
    props.path.search('easel_beta/homepage') ||
    props.path.search(/^\/$/) ||
    props.path.search(
      /^\/(paintings|photography|drawings|sculpture|collage|drawings\/pencil)$/i
    ) !== -1

  if (!isDesiredPath) {
    return null
  }

  return (
    <Wrapper
      className='trustpilot-widget'
      data-locale='en-US'
      data-template-id='53aa8912dec7e10d38f59f36'
      data-businessunit-id='55d204270000ff0005823f83'
      data-style-height='140px'
      data-style-width='100%'
      data-theme='light'
      data-stars='5'
      data-schema-type='Organization'
      styles={props.styles}
    >
      <iframe
        width='100%'
        frameBorder='0'
        scrolling='no'
        title='Customer reviews powered by Trustpilot'
        className='lazyload'
        data-src='https://widget.trustpilot.com/trustboxes/53aa8912dec7e10d38f59f36/index.html?locale=en-US&amp;templateId=53aa8912dec7e10d38f59f36
        &amp;businessunitId=55d204270000ff0005823f83&amp;styleHeight=130px&amp;styleWidth=100%25&amp;theme=light&amp;stars=5'
      />
    </Wrapper>
  )
}

export default TrustPilotWidgetConnector(TrustPilotWidget)
