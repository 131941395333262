import React from 'react'
import withRedux from 'next-redux-wrapper'
import Theme from 'components/Theme'
import { App, Store } from 'apps/saatchi/homepage'
import { Actions as PageActions } from 'actions/page'
import { storeSetup } from 'lib/pageSetup'
import { getDataLayer, getMetaData } from 'apps/saatchi/homepage/selectors'

const Homepage = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <Theme>
      <App />
    </Theme>
  )
}

Homepage.getInitialProps = async ({ req, store, res, query }) => {
  // Client-Side
  if (!req) {
    return {}
  }

  // SSR Logic
  storeSetup(store, req)
  // Page Data Setup
  const { page } = query
  store.dispatch(PageActions.SET(page))
  res.set('Edge-Cache-Tag', `page-homePage`)
  // Metadata & Datalayer
  const state = { ...store.getState() }
  const metaData = getMetaData(state)
  const dataLayer = getDataLayer(store.getState())
  return {
    dataLayer,
    metaData,
  }
}

export default withRedux(Store)(Homepage)
