import React, { FC } from 'react'
import UserPreferencesConnector, { ConnectorProps } from 'connectors/UserPreferences'

// If user is NOT in the US, but is using US PRICE ( DEFAULT )
// show 'USD' next to price. Only applies to USD as selected currency.
// Ex: User from Russia, currently we don't support Russian Ruble's
// Result: User is from Russia and Selected Currency is US, show USD
const Component: FC<ConnectorProps> = (props) => {
  const {
    userPreferencesCountryCode,
    selectedCurrency: { countryCode },
  } = props

  if (userPreferencesCountryCode !== 'US' && countryCode === 'US') {
    return <span>USD</span>
  }

  return null
}

export default UserPreferencesConnector(Component)
