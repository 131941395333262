import React, { FC } from 'react'
import SALink from 'components/SALink'
import { useCarouselSwipe } from 'hooks/carousel'
import Carousel from 'components/Carousel/Hero'
import HeroConnector, { ConnectorProps as HeroConnectorProps } from '../../connectors/Hero'
import { Dots, OpacityOverlay, TextCallout } from '../styles'
import ShortcutsSubMenu from '../ShortcutsSubMenu'
import { updateCriticalImagePath, capitalizeString } from 'lib/helpers'
import classNames from 'classnames'

const Hero: FC<HeroConnectorProps> = (props) => {
  const { heroItems, screenWidth } = props
  const { next, prev, index, navIndex, swipeIndex, setIndex, setSwipeIndex } = useCarouselSwipe(
    heroItems.length,
    screenWidth
  )
  const allowDrag = screenWidth <= 2436
  const showMobile = screenWidth <= 780
  const safeSetIndex = showMobile ? setSwipeIndex : setIndex
  const safeIndex = showMobile ? swipeIndex : index

  return (
    <>
      <div data-type='carouselWrapper'>
        <Carousel
          onNext={next}
          onPrev={prev}
          index={safeIndex}
          setIndex={safeSetIndex}
          controls
          draggable={allowDrag}
          screenWidth={screenWidth}
        >
          {heroItems.map((item, key) => {
            const { jpgLarge, jpgSmall, ctaLink, ctaText } = item
            const IMAGE_SUBFOLDER = 'saatchi-general'
            if (key === 0) {
              return (
                <div data-type='carouselItem' data-item={`${key}`} key={key}>
                  <OpacityOverlay />
                  <>
                    <SALink href={ctaLink}>
                      <picture data-type='image'>
                        <source
                          type='image/jpg'
                          media='(max-width:980px)'
                          srcSet={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgSmall)}
                        />
                        <img
                          width='1400'
                          height='600'
                          src={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgLarge)}
                          alt={`Slide ${key + 1}`}
                          itemProp='image'
                        />
                      </picture>
                      <noscript>
                        <img
                          width='1400'
                          height='600'
                          src={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgLarge)}
                          alt={`Slide ${key + 1}`}
                          itemProp='image'
                        />
                      </noscript>
                    </SALink>
                  </>

                  <SALink href={ctaLink}>
                    <div data-type='hero-copy-holder'>
                      <h2 className='defaultTypographyH1' data-type='hero-title'>
                        {item.title}
                      </h2>
                      <p className='defaultTypographyLarge' data-type='hero-description'>
                        {item.description}
                      </p>
                    </div>
                    <div data-type='cta-wrapper'>
                      <div data-type='cta'>
                        <p className={classNames('defaultTypographyCtaMedium', 'defaultTextBold')}>
                          {capitalizeString(ctaText)}
                        </p>
                      </div>
                    </div>
                  </SALink>
                </div>
              )
            } else {
              return (
                <div data-type='carouselItem' data-item={`${key}`} key={key}>
                  <OpacityOverlay />
                  <>
                    <SALink href={ctaLink}>
                      <picture data-type='image'>
                        <source
                          type='image/jpg'
                          loading='lazy'
                          media='(max-width:980px)'
                          srcSet={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgSmall)}
                        />
                        <img
                          width='1400'
                          height='600'
                          loading='lazy'
                          className='lazyload'
                          data-src={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgLarge)}
                          alt={`Slide ${key + 1}`}
                          itemProp='image'
                        />
                      </picture>
                      <noscript>
                        <img
                          loading='lazy'
                          className='lazyload'
                          width='1400'
                          height='600'
                          data-src={updateCriticalImagePath(IMAGE_SUBFOLDER, jpgLarge)}
                          alt={`Slide ${key + 1}`}
                          itemProp='image'
                        />
                      </noscript>
                    </SALink>
                  </>
                  <SALink href={ctaLink}>
                    <div data-type='hero-copy-holder'>
                      <h2 className='defaultTypographyH1' data-type='hero-title'>
                        {item.title}
                      </h2>
                      <p className='defaultTypographyLarge' data-type='hero-description'>
                        {item.description}
                      </p>
                    </div>
                    <div data-type='cta-wrapper'>
                      <div data-type='cta'>
                        <p className={classNames('defaultTypographyCtaMedium', 'defaultTextBold')}>
                          {capitalizeString(ctaText)}
                        </p>
                      </div>
                    </div>
                  </SALink>
                </div>
              )
            }
          })}
        </Carousel>
        <Dots
          data-type='heroDots'
          index={index}
          length={heroItems.length}
          setIndex={safeSetIndex}
          navIndex={navIndex}
        />
      </div>
      <ShortcutsSubMenu />
      <TextCallout>
        <h1 className='defaultTypographyH2'>
          Discover Art You Love From the World's Leading Online Gallery
        </h1>
      </TextCallout>
    </>
  )
}

export default HeroConnector(Hero)
